<template>
  <main>
    <div class="form">
      <div class="done">
      <h2>L'offre de remboursement n'a pas démarré, <br>nous vous invitons à revenir dès le 15 septembre 2021 </h2>
      <p><br /><br /></p>
      </div>
    </div>

  </main>
</template>
<script>
export default {
  name: 'finish',
  methods : {
    gohome() {
      this.$router.push("/");
    }
  }
}
</script>